@tailwind base;
@tailwind components;
@tailwind utilities;

*{
    scroll-behavior: smooth;
}

.video-container {
    position: relative;
}

.video-container video {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
    z-index: 0;
}

.redColor{
    color: #F4650E;
}

.redBg{
    background-color: #F4650E;
}

